body,
body * {
  margin: 0;
  padding: 0;
  width: "100%";
  font-family: "sweet-sans-pro" sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-family: sweet-sans-pro;
  font-weight: 600;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
html .content {
  overflow-y: hidden;
}
body {
  font-family: sweet-sans-pro;
  font-weight: 600;

  margin: 0;
  padding: 0;
}

code {
  font-family: sweet-sans-pro;
  font-weight: 600;
}
